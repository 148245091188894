import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type PaperProps = DetailedHTMLProps<
	HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;

function Paper({ className, ...props }: PaperProps) {
	return <div {...props} className={twMerge('p-10 bg-slate-700', className)}></div>;
}

export default Paper;
