const EOnlineRoutes = {
	DASHBOARD: '/',
	PROFILES: '/profiles',
	PROFILE_CREATE: '/profiles/new',
	PROFILE_VIEW: '/profiles/:profileId',
	PROFILE_EDIT: '/profiles/:profileId/edit',
	PITCH_DECKS: '/pitch-decks',
	PITCH_DECK_CREATE: '/pitch-decks/new',
	PITCH_DECK_EDIT: '/pitch-decks/:pitchDeckId',
	CAMPAIGNS: '/campaigns',
	CAMPAIGN_CREATE: '/campaigns/new',
	CAMPAIGN_VIEW: '/campaigns/:campaignId',
	CAMPAIGN_EDIT: '/campaigns/:campaignId/edit',
	SETTINGS: '/settings',
	SETTINGS_PASSWORD: '/settings/password',
};

export default EOnlineRoutes;
