import { useForm } from 'react-hook-form';
import Paper from '~/app/components/Paper';
import supabase from '~/app/configs/supabase';
import { defaultValues } from './form';
import { Button, PasswordInput, TextInput } from '@mantine/core';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import EOnlineRoutes from '~/app/routes/online/enums';

function LoginRoute() {
	const navigate = useNavigate();
	const { register, handleSubmit } = useForm({ defaultValues });

	return (
		<Paper className="w-full max-w-sm rounded-xl">
			<h1 className="text-center text-2xl font-bold">Login</h1>

			<form
				onSubmit={handleSubmit(async (values) => {
					const { data, error } =
						await supabase.auth.signInWithPassword({
							email: values.email,
							password: values.password,
						});

					if (data.user) {
						navigate(EOnlineRoutes.DASHBOARD);

						toast.success('Login successful!');
					} else {
						toast.error('Error: ' + error?.message);
					}
				})}
				className="mt-5"
			>
				<div className="flex flex-col gap-3">
					<TextInput
						label="E-Mail"
						autoComplete="address-level1"
						{...register('email', { required: true })}
					/>
					<PasswordInput
						label="Password"
						autoComplete="address-level1"
						{...register('password', { required: true })}
					/>
				</div>
				<div className="mt-10 flex items-end justify-end gap-10">
					<Button type="submit">Login</Button>
				</div>
			</form>
		</Paper>
	);
}

export default LoginRoute;
