import { setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import { DatesProviderSettings } from '@mantine/dates';

const weekStartsOn = 1;

setDefaultOptions({
	weekStartsOn,
	locale: de,
});

export const datesSettings: DatesProviderSettings = {
	locale: 'de',
	firstDayOfWeek: weekStartsOn,
	weekendDays: [0, 6],
	timezone: 'UTC',
};
