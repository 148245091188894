import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type LayoutCenteredProps = { children?: ReactNode; className?: string };

function LayoutCentered({
	children = <Outlet />,
	className,
}: LayoutCenteredProps) {
	return (
		<div
			className={twMerge(
				'absolute top-0 left-0',
				'w-full h-full',
				'flex flex-col items-center justify-center p-5',
				className,
			)}
		>
			{children}
		</div>
	);
}

export default LayoutCentered;
