import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import tiktokButton from '~/assets/tiktok.button_black.png';

function JoinRoute() {
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const type = searchParams.get('type');
		const error = searchParams.get('error');
		const success = searchParams.get('success');

		if (type && (error || success))
			if (error) {
				const data = JSON.parse(error) as {
					code: 'string';
					message: string;
				};
				toast.error(data.message);
			} else if (success) {
				const data = JSON.parse(atob(success));

				Object.entries(data).forEach(([key, value]) => {
					localStorage.setItem(`${type}_${key}`, `${value}`);
				});

				toast.success('Success!!!');
			}
	}, [searchParams]);

	return (
		<div className="min-w-full min-h-[100vh] flex flex-col items-center justify-center">
			<h1 className="font-extrabold text-3xl text-slate-600">Join Now</h1>

			<div className="mt-10 flex flex-col gap-5">
				<button
					type="button"
					className="p-0 m-0 bg-transparent border-none outline-none cursor-pointer"
					onClick={() => {
						window.location.href = '/join/tiktok';
					}}
				>
					<img src={tiktokButton} />
				</button>
			</div>
		</div>
	);
}

export default JoinRoute;
