import { createTheme, MantineColorsTuple, NumberInput } from '@mantine/core';
import { indigo, slate } from 'tailwindcss/colors';

const mantineTheme = createTheme({
	defaultRadius: 'sm',
	primaryColor: 'indigo',
	colors: {
		indigo: [
			indigo[50],
			indigo[50],
			indigo[100],
			indigo[200],
			indigo[300],
			indigo[400],
			indigo[500],
			indigo[600],
			indigo[700],
			indigo[800],
		],
		slate: Object.values(slate) as unknown as MantineColorsTuple,
	},
	lineHeights: { md: '1' },
	components: {
		NumberInput: NumberInput.extend({
			defaultProps: {
				decimalSeparator: ',',
				allowedDecimalSeparators: ['.', ','],
				thousandSeparator: ' ',
			},
		}),
	},
});

export default mantineTheme;
