import { lazy, Suspense, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/app.scss';
import '@mantine/core/styles.css';
import OfflineRoutes from './routes/offline';
import supabase from './configs/supabase';
import { Session } from '@supabase/supabase-js';
import { Loader, MantineProvider } from '@mantine/core';
import mantineTheme from '../styles/mantine';
import { ToastContainer } from 'react-toastify';
import LayoutCentered from './layouts/Centered';
import { AuthContextProvider } from './stores/auth';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './configs/react-query';
import { DatesProvider } from '@mantine/dates';
import { datesSettings } from './configs/dates';
const OnlineRoutes = lazy(() => import('./routes/online'));

function App() {
	const [session, setSession] = useState<undefined | null | Session>(
		undefined,
	);

	useEffect(() => {
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
		});

		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((_event, session) => {
			setSession(session);
		});

		return () => subscription.unsubscribe();
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<MantineProvider theme={mantineTheme}>
				<DatesProvider
					settings={datesSettings}
				>
					<Suspense>
						{session === undefined ? (
							<LayoutCentered>
								<Loader size="xl" />
							</LayoutCentered>
						) : session ? (
							<AuthContextProvider value={session}>
								<OnlineRoutes />
							</AuthContextProvider>
						) : (
							<OfflineRoutes />
						)}
					</Suspense>

					<ToastContainer theme="dark" />
				</DatesProvider>
			</MantineProvider>
		</QueryClientProvider>
	);
}

export default App;
