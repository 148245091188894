import { Button } from '@mantine/core';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import LayoutCentered from '~/app/layouts/Centered';
import EOfflineRoutes from '~/app/routes/offline/enums';
import EOnlineRoutes from '~/app/routes/online/enums';
import { useAuth } from '~/app/stores/auth';

type PageErrorProps = { children?: ReactNode };

function PageError({ children = 'An error occurred...' }: PageErrorProps) {
	const isOnline = useAuth();

	return (
		<LayoutCentered className="relative">
			<div className="text-3xl font-bold">{children}</div>

			{isOnline ? (
				<Button
					component={Link}
					to={EOnlineRoutes.DASHBOARD}
					className="mt-10"
				>
					Zum Dashboard
				</Button>
			) : (
				<Button
					component={Link}
					to={EOfflineRoutes.LOGIN}
					className="mt-10"
				>
					Start
				</Button>
			)}
		</LayoutCentered>
	);
}

export default PageError;
