import { RouteObject } from 'react-router-dom';
import EAnyStateRoutes from './enums';
import JoinRoute from './JoinRoute';

const anyStateRoutes: RouteObject[] = [
	{
		path: EAnyStateRoutes.JOIN,
		element: <JoinRoute />,
	},
];

export default anyStateRoutes;
