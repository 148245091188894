import { Session } from '@supabase/supabase-js';
import { createContext, useContext } from 'react';

const authStore = createContext<Session>(undefined as unknown as Session);

export const AuthContextProvider = authStore.Provider;

export function useAuth() {
	const auth = useContext(authStore);

	return auth;
}
