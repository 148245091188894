import {
	createBrowserRouter,
	Navigate,
	RouterProvider,
} from 'react-router-dom';
import LayoutCentered from '~/app/layouts/Centered';
import EOfflineRoutes from './enums';
import LoginRoute from './LoginRoute';
import PageError from '~/app/pages/Error';
import anyStateRoutes from '../any';

const offlineRouter = createBrowserRouter([
	{
		path: '/',
		children: [
			{
				path: '/',
				element: <Navigate to={EOfflineRoutes.LOGIN} replace />,
			},
			{
				path: EOfflineRoutes.LOGIN,
				element: (
					<LayoutCentered>
						<LoginRoute />
					</LayoutCentered>
				),
			},
		],
	},
	...anyStateRoutes,
	{
		path: '*',
		element: <PageError />,
	},
]);

const OfflineRoutes = () => <RouterProvider router={offlineRouter} />;

export default OfflineRoutes;
